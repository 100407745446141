import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/StickerPay"
import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewFooter } from "../../components/NewFooter"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"
import { PageData } from "../../interfaces/pageProps"
import { Advantages } from "../../components/AdvantagesForBanner"

import { ADVANTAGES_STICKER_PAY } from "../../components/AdvantagesForBanner/helper"
import { getPageData } from "../../helpers/getPageData"

const bannerTitle = <>Ваш друг рекомендует вам оформить стикер&#160;Pay!</>
const bannerDescription = ""

const fragmentAsString = bannerTitle.props.children

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA withoutHintAndCountDown buttonText="Оформить" />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : fragmentAsString}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : fragmentAsString}
          orderNum="1"
          buttonText="Оформить"
        />
      )}
      <Advantages items={ADVANTAGES_STICKER_PAY} />
      <HowInstallmentWorks
        headTitle="Оформите стикер прямо сейчас"
        variant="stickerPay"
        orderNum="5"
      />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title="Оформите стикер"
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="4"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://sovcombank.ru/apply/cards/new-sticker-pay/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
